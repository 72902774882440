import React from "react";

import { Button, Col, Divider, Row } from "antd";
import * as styles from "./index.module.scss";

import { Helmet } from "react-helmet";

import { graphql, navigate } from "gatsby";
import Img from "gatsby-image";

import Header from "@components/header";

import Footer from "@components/footer";

const handleRegister = (event) => {
  event.preventDefault();
  navigate("/register/");
};

const Partner = ({ data }) => {
  const image1 = data.image1.childImageSharp.fluid;
  const image2 = data.image2.childImageSharp.fluid;
  const image3 = data.image3.childImageSharp.fluid;
  const image4 = data.image4.childImageSharp.fluid;
  const image5 = data.image5.childImageSharp.fluid;
  const image6 = data.image6.childImageSharp.fluid;
  const footerBg = data.footerBg.childImageSharp.fluid;
  const smallFooterBg = data.smallFooterBg.childImageSharp.fluid;

  const renderData = [
    {
      title: "战略合作伙伴",
      imgs: [image1],
    },
    {
      title: "支付合作伙伴",
      imgs: [image2, image3, image4],
    },
    {
      title: "ERP合作伙伴",
      imgs: [image5, image6],
    },
  ];

  return (
    <div className={styles.partner}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>合作伙伴-Shoprise 官网</title>
      </Helmet>

      <Header />
      <div className={styles.title}>
        <h1>合作伙伴</h1>
        <h4>Partner</h4>
      </div>
      <div className={styles.content}>
        {renderData.map((item) => (
          <div className={styles.contentItem} key={item.title}>
            <div className={styles.itemHeader}>{item.title}</div>
            <Divider className={styles.divider} />
            <div className={styles.itemGroup}>
              {item.imgs.map((image, index) => (
                <div className={styles.imgContainer} key={index}>
                  <Img fluid={image} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.footerShow}>
        <Row>
          <Col xs={0} sm={0} md={24}>
            <div className={styles.footerBgContainer}>
              <Img fluid={footerBg} />
                <Button
                  type="primary"
                  shape="round"
                  onClick={handleRegister}
                  className={styles.button}
                >
                  立即免费使用30天
                </Button>
            </div>
          </Col>
          <Col xs={24} sm={24} md={0}>
            <div className={styles.footerBgContainer}>
              <Img fluid={smallFooterBg} />

                <Button
                  type="primary"
                  shape="round"
                  onClick={handleRegister}
                  className={styles.button}
                >
                  立即免费使用30天
                </Button>
            </div>
          </Col>
        </Row>
      </div>

      <Footer />
    </div>
  );
};

export default Partner;

export const partnerImg = graphql`
  fragment partnerImg on File {
    childImageSharp {
      fluid(quality: 100, maxWidth: 190) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "partner/img1.png" }) {
      ...partnerImg
    }
    image2: file(relativePath: { eq: "partner/img2.png" }) {
      ...partnerImg
    }
    image3: file(relativePath: { eq: "partner/img3.png" }) {
      ...partnerImg
    }
    image4: file(relativePath: { eq: "partner/img4.png" }) {
      ...partnerImg
    }
    image5: file(relativePath: { eq: "partner/img5.png" }) {
      ...partnerImg
    }
    image6: file(relativePath: { eq: "partner/img6.png" }) {
      ...partnerImg
    }
    footerBg: file(relativePath: { eq: "partner/footerBg.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1902) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    smallFooterBg: file(relativePath: { eq: "partner/smallFooterBg.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 750) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
