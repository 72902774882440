// extracted by mini-css-extract-plugin
export var button = "index-module--button--cKHvl";
export var content = "index-module--content--eC1pK";
export var contentItem = "index-module--contentItem--6tM91";
export var divider = "index-module--divider--DyNnM";
export var footerBgContainer = "index-module--footerBgContainer--6LP9Q";
export var footerShow = "index-module--footerShow--WZQHe";
export var imgContainer = "index-module--imgContainer--yskR8";
export var itemGroup = "index-module--itemGroup--dWNW0";
export var itemHeader = "index-module--itemHeader--JViG8";
export var partner = "index-module--partner--oxoRt";
export var title = "index-module--title--W0+LZ";